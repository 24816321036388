@import url('https://fonts.cdnfonts.com/css/sweet-sans-pro');

.New-product-main-container{
height: max-content;
  display: flex;
  flex-direction: column;

}
.Cashier-item-list-editing {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1%;
  padding-bottom: 2%;
  flex-direction: row;
  justify-content: space-evenly;
}

.New-Product-item-Img {
  height: 12rem;
  margin: 0px;
  background-color: white;
  margin:0;
  border-radius: 17px;
}

.New-item-img__full {
  color: #fafafa;
  background-color: #137CC1;
  right: 12%;
  border-radius: 0px 17px 0px 0px ;
}

.Max-item-img__full {
  color: #fafafa;
  background-color: #0F1D3C;
  right: 1.7rem;
  border-radius: 0px 17px 0px 0px ;
}


.Cashier-item-img-quantity {
  color: #f7f7f7;
}

.New-product-item-name {
  font-family: 'Sweet Sans Pro';
  color: #000;
  text-align: center;
  font-size: 1rem;
  max-height: 2.5rem;
  min-height: 2.1rem;
  max-width: 80%;
  line-height: 1.2rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  margin: 0px;
  -webkit-box-orient: vertical;
  align-self: center;
  margin-bottom: -0.8rem;
}

.New-product-item-price {
  text-align: center;
  font-family: 'Sweet Sans Pro';
  font-size: 1.2rem;
  color: #000;
  margin-top: -6px;
  font-weight: 600;

}
.Max-product-item-price {
  text-align: center;
  font-family: 'Sweet Sans Pro';
  font-size: 1.2rem;
  color: #0F1D3C;
  margin-top: -6px;
  font-weight: 600;

}

.New-product-item-price-withOutPromo {
  text-align: center;
  font-family: 'Sweet Sans Pro';
  font-size: 1.2rem;
  font-family: 'Sweet Sans Pro';
  color: #000;
  margin: 0px;
  font-weight: 600;
}

.Max-product-item-price-withOutPromo {
   text-align: center;
   font-family: 'Sweet Sans Pro';
   font-size: 1.2rem;
   font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
   margin: 0px;
   font-weight: 600;
 }


.New-product-Purchase {
  text-align: center;
  font-family: 'Sweet Sans Pro';
  align-self: center;
  font-size: 1.1rem;
  alignItems: center;
  font-family: 'Sweet Sans Pro';
  background-color: #137CC1;
  margin: 0.5rem 0px 0px 0px;
  width: 70%;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 47px;
  cursor: pointer;
}



.New-product-Purchase-unn {
  text-align: center;
  align-self: center;
  font-size: 1.1rem;
  alignItems: center;
  font-family: 'Sweet Sans Pro';
  background-color: silver;
  padding-bottom: 2px;
  margin: 0.5rem 0px 0px 0px;
  width: 100%;
  color: #fff;
  height: 2rem;
  border-radius: 47px;
}

.Cashier-item-controls {
  display: flex;
  justify-content: center;
}

.New-item-price-Promo {
  margin: 0;
  text-align: center;
  font-family: 'Sweet Sans Pro';
  color: rgba(107, 104, 112, 0.51);
  font-size: 13px;
  text-decoration: line-through;
}

.New-item-price-Container {
  display: flex;
  flex-direction: column;
  height: 3rem !important;
  margin-top: 10px;
}

.ampm-Icon-Outlined-Gift-card {
  width: 16% !important;
  color: #F12535;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1% !important;
  left: 12.5% !important;

  border-radius: 5px;
  aspect-ratio: 1;

}
.New-item-controls-content{
  display: flex;
  height: 91px;
  padding-inline: 8%;
  flex-direction: column;
}
.New-Icon-Promotion-Text{
  color: #F12535;
  display: block;
  font-size: 1rem;
  margin-top: -0.4rem;
  margin-left: 0.5rem;
  font-family: 'Sweet Sans Pro';
}

.New-product-item-img{
  height: max-content;
  min-height: 12rem;
  width: 100% !important;
  display: flex;
  color: silver;
  cursor: pointer;
  text-align: center;
  font-family: 'Sweet Sans Pro';
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.New-product-item {
  height: 23rem!important;
  margin-bottom: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 270px;
  margin-inline: 0.3rem;
}
@media screen and (max-width: 750px) {
  .New-product-item {
    width: 178px;
   }
  .New-item-img__full {
    right: 0.3rem;
  }
  .New-item-price-Promo {
    margin-top: 0.6rem;
    margin-bottom: -0.3rem;
  }
}

@media screen and (min-width: 750px) and (max-width: 1050px) {
  .New-product-item {
    width: 220px;
   }
  .New-product-Purchase{
    padding-bottom: 2px;
  }
  .New-item-img__full {
    right: 1.8rem;
  }
  .Max-item-img__full {
    right: 1.8rem;
  }
}

@media screen and (min-width: 1050px) and (max-width: 1400px){
  .New-product-item {
    width:230px;
    height: 25rem!important;
    }
  .New-item-img__full {
    right: 1.7rem;
  }
  .Max-item-img__full {
    right: 1.7rem;
  }
  .New-product-Purchase{
       padding-bottom: 2px;
     }

}

@media screen and (min-width: 1400px) {
  .New-product-item {
    width: 16rem;
    height: 25rem!important;
   }
  .New-product-Purchase{
    padding-bottom: 2px;
  }
}