.skeletonPayUpse{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.skeletonPrice{
  display: flex;
  justify-content: center;
  margin: 16px 0;
}
.skeletonPrice > span{
  margin-top: 8px !important;
  height: 44px !important;
  width: 225px !important;
}
.skeletonInputLogo{
  border-radius: 50%;
}
.skeletonInputRemember{
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}
.skeletonInputRemember > span{
  width: 80% !important;
  height: 20px !important;
}
.skeletonInputAdvices{
  margin-bottom: 2px;
}
.skeletonInputAdvices > span{
  width: 90% !important;
  height: 110px !important;
}
.skeletonForm > span{
  width: 90% !important;
  height: 550px !important;
}
.skeletonInputs{
  display: flex;
  justify-content: center;
}
.skeletonInput > span{
  width: 90% !important;
  height: 500px !important;
}
.skeletonButton{
  display: flex;
  justify-content: right;
  margin: 8px 25px 10px 5px;
}
.skeletonButton > span {
  height: 36px !important;
  width: 64px !important;
}
@media only screen and (max-width: 766px) {
  .skeletonInput > span {
    height: 440px !important;
  }
  .skeletonButton{
    margin: 8px;
  }
}