.Content-address {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 2.5rem 0;
}

.Content-address-title {
    font-size: 2.8rem;
    text-transform: capitalize;
}

.Content-address-list {
    display: flex;
    margin: 1.5rem 0;
    flex-direction: column;
    justify-content: center;
}

.Content-address-list-subTitle {
    display: flex;
    font-size: 1.7rem;
    justify-content: center;
}

.ant-list-split .ant-list-item {
    border-bottom: 1px solid #1890ff;
}

.Content-address-card-body {
    font-size: 1rem;
    text-transform: capitalize;
    width: 60%!important;
    max-width: 60%!important;
    min-width: 60%!important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Content-address-card-button {
    height: 50px;
    width: 274px;
    border: 1px solid #00000040;
}

.Content-address > button {
    border-color: #1890ff !important;
    background-color: #1890ff !important;
}

.Content-address-card {
    height: 50px;
    width: 274px;
    display: flex;
    margin: 1rem 0;
    cursor: pointer;
    border-radius: 4px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.Content-address-card > span {
    font-size: 0.8rem;
}

.Content-address-card:hover {
    border: 1px solid #91C48A;
}

.ant-card-bordered {
    height: 120px;
    margin: 0.2rem 0.5rem;
    border: 1px solid #f0f0f0;
}

.Content-address > button:hover {
    color: #1890ff !important;
    border: 1px solid #1890ff !important;
    background-color: #ffffff !important;
}

@media screen and (min-width: 376px) {
    .Content-address-card {
        width: 370px;
    }
    .Content-address-card > span {
        font-size: 1rem;
    }
    .Content-address-card-button {
        width: 370px;
    }
}

@media screen and (min-width: 769px) {
    .Content-address-card {
        width: 410px;
    }
    .Content-address-card > span {
        font-size: 1rem;
    }
    .Content-address-card-button {
        width: 410px;
    }
}
