@import url('https://fonts.cdnfonts.com/css/sweet-sans-pro');
@media screen and (max-width: 800px) {
.category-img {
  width: 100%!important;
  display: flex;
  height: 100px!important;
  text-align: center;
  aspect-ratio: 3/2!important;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}

.category-name {
  min-width: 100% !important;
  display: flex;
  font-size: smaller;
  text-align: center;
  font-family: 'Sweet Sans Pro';
  align-items: center;
}

.category-item-name{
  overflow: hidden;
  font-size: 1rem;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  margin-bottom: 0.5rem;
  min-width: 100% !important;
  -webkit-box-orient: vertical;
}

  .max-category-item-name{
    overflow: hidden;
    font-weight: 700;
    text-align: center;
    font-family: 'Sweet Sans Pro';
    color: #000;
    font-size: 1rem;
    text-align: center;
    display: -webkit-box;
    text-overflow: ellipsis;
  }


.category-item {
  width: 45%;
  border: 0px;
  cursor:pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  flex-direction: column;


}
.Cashier-item {
  width: 45%;
  float: left;
  height: 100%;
  display: flex;
  margin-top: 10px;
  margin-left: 3.3%;
  font-size: 1.5rem;
  border-radius: 3px;
  align-items: center;
  flex-direction: column;
  background-color: transparent!important;
  border: 0px!important;
}

.Max-category-item {
  width: 45vw;
}

.category-item-content{
  align-items: center;
  width: 100%;
  height: 10.5rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.max-category-item-content{
  align-items: center;
  min-width: 45vw !important;
  height: 16rem!important;
  display: flex;
  flex-direction: column;
  margin-bottom: -1rem;
}

.category-item-productImg{
  object-fit: contain;
  width: 100%;
  aspect-ratio: 3/2!important;
  border-radius: 28px;
  max-width: 11.4rem;
}
.category-item-productImg-Max{
  width: 100%;
  aspect-ratio: 1!important;
  border-radius: 28px;
  max-width: 11.4rem;
  max-height: 11.4rem;
  margin: 0;
  padding: 0;
  object-fit: cover;
}
  .category-item-noImg-Max{
    width: 100%;
    aspect-ratio: 1!important;
    border-radius: 28px;
    max-width: 11.4rem;
    max-height: 11.4rem;
    margin: 0;
    height: 100%;
    font-size: 5rem;
    padding: 0;
    object-fit: contain;
    padding-top: 3rem;
    color: rgba(0, 0, 0, 0.2);
  }

.category-item-productIcon {
  width: 100%;
  height: 90%;
  color: silver;
  cursor: pointer;
}
.category-list-editing-ampm{
  display: flex;
  flex-direction: column;
}
}
@media screen and (min-width: 800px) {
  .category-item-productImg-Max{
    width: 90%;
    height: 11rem;
    border-radius: 28px;
    margin: 0;
    padding: 0;
    object-fit: cover;
  }

  .max-category-item-content{
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: -1rem;
  }
  .Max-category-img {


    display: flex;
    background-size: contain;
    width: 8vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor:pointer;
  }
  .Max-category-item-productImg{
    object-fit: contain;
    width: 100%!important;
    aspect-ratio: 3/2!important;
    border-radius: 28px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    cursor:pointer;
  }
  .Max-category-item {
    border: 0px;
    cursor:pointer;
    height: max-content;
    margin-bottom: 2rem;
  }
  .Max-category-item-productImg{
    object-fit: contain;
    width: 100%;
    height: max-content;
    aspect-ratio: 3/2!important;
    border-radius: 28px;
  }
  .Max-category-name {
    width: 95%;
    max-width: 18vw;
    height: 40px;
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .max-category-item-name{
    overflow: hidden;
    font-weight: 700;
    text-align: center;
    font-family: 'Sweet Sans Pro';
    color: #000;
    font-size: 1rem;
    text-align: center;
    display: -webkit-box;
    text-overflow: ellipsis;
  }

  .category-img {


    display: flex;
    background-size: cover;
    width: 18vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor:pointer;
  }
  .category-item-productImg{
    width: 100%!important;
    aspect-ratio: 3/2!important;
    border-radius: 28px;
    object-fit: contain;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    cursor:pointer;
  }
  .category-item {
    border: 0px;
    background-color: transparent;
    cursor:pointer;
  }
  .category-item-productImg{
    width: 100%;
    object-fit: contain;
    height: max-content;
    aspect-ratio: 3/2!important;
    border-radius: 28px;
  }
  .category-name {
    width: 95%;
    max-width: 18vw;
    height: 40px;
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .category-item-name {
    font-weight: 500;
    font-family: 'Sweet Sans Pro';
    font-size: 1.2rem;
    overflow: hidden;
    text-align: left;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
  .Cashier-item {
    background-color: transparent!important;
    border: 0px!important;
  }
}