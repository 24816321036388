.ant-select {
    width: 100%;
    margin-bottom: 15px;
}
.billingInformation-title{
    font-size: 16px;
    padding-bottom: 10px;
}
.Form-input{
    color:#000
}
.Form{
    margin-left: 0!important;
}

.Form-billing {
    margin: 0 1rem !important;
    background-color: white;
    padding: 0 1rem;
}


@media screen and (min-width: 768px){
    .Form-billing {
        margin: 0 5rem !important;
        padding: 0 1rem;
    }
}