.new-header-cart-icon {
  margin-right: 0.5rem;
  font-size: 2.1rem !important;
}
.ant-radio-button-inner{
  display: none!important;
}
.new-header-cart-icon__full {
  color: #70b96c;
  text-overflow: ellipsis;
  -webkit-box-orient: horizontal;
}

.editing-paymentoptions-container{
  display: flex;
  height: 80%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.editing-paymentoptions-text{
  color:#137CC1;
  font-family: 'Sweet Sans Pro';
  margin-bottom: 2rem!important;
  font-size: 1.3rem;
}
.ant-radio-button ant-radio-button-checked{
  color: white !important;
  background: #137CC1 !important;
  border-color: #137CC1 !important;
}

.new-header-cart-modal {
  width: 58vw;
  margin-inline: 3vw;
  max-height: 90vh;
  height: max-content;
  overflow-y: scroll;
  position: absolute;
  top:5%;
  left: 300px;
  z-index: 999;
  background-color: #FFFFFF;
  border-radius: 17px;
}

.Cart-switch {
  margin-left: 0.5rem;
  width: 0.5rem;
}

.billingInformation-title{
  color:#137CC1;
  font-family: 'Sweet Sans Pro';
}

.ant-switch-checked {
  background: #00704A;
}

.ant-switch:focus{
  outline: none;
  box-shadow: none;
  border-color: transparent;
}
.New-Cart-switch-billing-information {
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0.7rem;
  align-items: center;
}

.new-header-cart-modal::-webkit-scrollbar {
  display: none;
}

.new-cart-title{
  margin:0 0.5rem 0 1rem;
  font-family: 'Sweet Sans Pro';
  color: #137CC1;
  font-size: 1.3rem;
}
.new-header-cart-modal-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  padding-inline: 0.5rem;
}

.New-Cart-Modal-Opacity{
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.58);
}

.new-header-cart-modal-clean {
  left: 0;
  top: 0;
  width: 100%;
  height: 35px;
  display: flex;
  z-index: 10000;
  position: absolute;
  align-items: center;
  background-color: #fafafa;
  justify-content: space-between;
}

.new-header-cart-modal-clean span {
  margin-left: 10px;
}

.new-header-cart-modal-clean u {
  cursor: pointer;
  margin-right: 10px;
}

.new-header-cart-modal-close-btn {
  font-size: 1.2rem;
  margin: 0;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  padding: 0;
  color: #137CC1;
}
.New-cart-products-container{
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.new-header-cart-modal-product {
  width: 90%;
  padding-right: 1rem;
  height: 5rem!important;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.new-header-cart-modal-product-info {
  width: 60%;
  float: left;
  display: flex;
  flex-direction: initial;
}

.new-data-product-cart {
  display: flex;
  min-height: 90%;
  min-width: 8rem!important;
  flex-direction: column;
  justify-content: space-evenly;
  padding-block: 1rem;
}
.New-cart-item-productImg{
  width: 4rem;
  height: 4rem;
  margin: 5px;
  background-size: contain;
}
.New-Cart-Icon-Outlined-Gift {
  z-index: 1;
  color: #FF0000;
  text-align: center;
}

.NewCart-Card-Flag{
  position: relative;
  top: 5px;
  left: 0px;
  width: 25%;
  height: 25px;
  font-size: 0.7rem;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #F12535;
  font-family: 'Sweet Sans Pro';
  border-radius: 8px;
}

.NewCart-TagsFilled{
  font-size: 1rem;
  color: #F12535;
}

.new-cart-item-name {
  font-size: 0.8rem;
  max-height: 2rem;
  line-height: 13px;
  text-overflow: ellipsis;
  margin-top: 5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  font-family: 'Sweet Sans Pro';
  text-overflow: ellipsis;
  color: #137CC1;
  -webkit-box-orient: vertical;
}

.modal-content {
  padding: 0;
  width: 100%;
  max-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-cart-item-measure {
  color: #b5b5b5;
  font-size: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.new-cart-item-name-total {
  float: left;
  display: flex;
  font-size: 14px;
  color: rgba(0,0,0,.85);
  flex-direction: column;
  align-items: flex-start;
}

.Span-total-name {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.85);
}

.Span-total-cant-name {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
}

.loyalty-right-icon{
  color:#137CC1!important;
  margin-right: 1rem!important;
  margin-left: 0.5rem ;
  margin-top: 0.1rem;
  font-size: 1.3rem;
}

.Span-total-loyalty-points {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
}

.new-total-price {
  display: flex;
  justify-content: left;
  flex-direction: column;
}

.new-cart-item-price {
  font-size: 1rem;
  color: #137CC1;
  font-family: 'Sweet Sans Pro';
}

.new-cart-item-price-payment-method {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
}

.new-cart-content-price-total {
  float: right;
  margin-top: 5px;
  display: inline-flex;
  flex-direction: column-reverse;
}

.new-cart-item-price-total {
  float: left;
  line-height: 17px;
  font-family: "Sweet Sans Pro";
  color: rgba(0, 0, 0, 0.85);
}
ant-select-arrow{
  font-size: 0.3rem!important;
  color: #137CC1;
}

.New-cart-quantity-select{
width:50px!important;
;
}
.new-cart-method-T{
  font-family: "Sweet Sans Pro";
  color: #137CC1;
  font-size: 1rem;
  padding-inline: 5px;
  margin-bottom: 0.8rem!important;

}
.new-cart-method-Cont{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-cart-icon-payment-method {
  padding: 5px;
  color: #137CC1!important;
  font-size: 2rem;
}
.new-cart-add-button{
  border-radius: 50%;
  height: 1.3rem;
  width: 1.3rem;
  color: #137CC1;
  background-color: #F8F1E5;
  display: flex;
  font-weight: 700;
  font-size: 1.3rem;
  justify-content: center;

  align-items: center;
  padding-bottom: 0.2rem;
}
.new-cart-quantity{
  color: #137CC1;
  font-size: 1rem;
}
.new-header-cart-modal-product-adding {
  display: flex;
  width: 4rem;
  height: min-content;
  align-items: center;
  justify-content: space-between;

}

.new-header-cart-modal-product-adding .new-item-adding-btn {
  border: none !important;
  background-color: #fafafa !important;
}

.new-header-cart-modal-product-adding .btn-floating {
  box-shadow: none;
  background-color: transparent !important;
}

.new-header-cart-modal-product-adding .new-item-adding .btn-floating[disabled] {
  background-color: #fafafa !important;
}

.new-header-cart-modal-product-adding .new-item-adding .new-item-adding-btn:hover, .new-item-adding-btn:focus {
  border: none !important;
  background-color: #fafafa !important;
}

.new-header-cart-modal-product-adding .btn-floating[disabled] i {
  color: #9F9F9F !important;
}

.new-header-cart-payBTN {
  width: 90%;
  background-color: #137CC1;
}

.new-cart-order-confirm {
  display: block;
}

.editing-cart-order-confirm-btn{
  text-align: center;
  font-family: 'Sweet Sans Pro';
  align-self: center;
  alignItems: center;
  font-size: 1rem!important;
  text-transform: capitalize!important;
  font-family: 'Sweet Sans Pro';
  background-color: #137CC1!important;
  padding-bottom: 2px;
  margin: 2rem 0px 0px 0px;
  width: 12rem;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 47px;
  cursor: pointer;
}

.new-header-cart-payBTN:hover, .new-cart-order-confirm-btn:hover {
  color: white;
  background-color: #a1cc9c!important;
}

.new-header-cart-modal-product-footer {
  z-index: 1;
  padding: 1%;
  width: 100%;
  height: max-content;
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  flex-direction: column;
  background-color: #fafafa;
  justify-content: space-evenly;
}

.new-divider-cart {
  margin: 0 0;
}

.new-cart-total {
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding-block: 1rem;
}

.new-cart-payment-methods {
  width: 90%;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.new-radio-payment-methods {
  width: 100%;
  margin-bottom: 40px;
}

.ant-drawer-close {
  border: none !important;
  background-color: transparent !important;
}

.ant-drawer-close:hover .ant-drawer-close:focus {
  border: none !important;
  background-color: transparent !important;
}

.ant-radio-group .ant-radio-group-outline {
  width: 100%;
  display: inline-grid;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #137CC1!important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #137CC1;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #137CC1 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #137CC1;
  background: #fff;
  border-color: #137CC1;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #137CC1;
  border-color: #137CC1;
}

.ant-radio-button-wrapper:hover {
  color: #137CC1;
}

.ant-drawer-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

[type="radio"]:checked + span:after, [type="radio"].with-gap:checked + span:after {
  background-color: transparent;
}

[type="radio"]:not(:checked)+span:before, [type="radio"]:not(:checked)+span:after {
  border: transparent;
}

[type="radio"]:checked + span:after, [type="radio"].with-gap:checked + span:before, [type="radio"].with-gap:checked + span:after {
  border: transparent;
}

.ant-btn-primary {
  color: white !important;
  background: #70b96c !important;
  border-color: #137CC1 !important;
}

.ant-btn-primary:hover {
  color: white !important;
  background: #a1cc9c !important;
  border-color: #a1cc9c !important;
}

.ant-btn {
  border: none;
  background-color: transparent;
}

.adm-button:focus {
  color: #ffffff;
  background-color: #878d87 !important;
}

.new-swipe-action-cart {
  background: transparent !important;
}
.Cart-loyaltyPoints-Title{
  color:#137CC1;
  font-family: 'Sweet Sans Pro';
}

.ant-drawer-content-wrapper {
  height: 100%;
}

.Cart-loyaltyPoints {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1.7rem;
}

.Cart-loyaltyPoints-input {
  display: flex;
  align-items: center;
}

.New-cart-Loyalty-Input-C{
  display: flex;

  height: 2rem;
  align-items: center;
  color:#000;
}
.Cart-input-addPoints {
  height: 2rem!important;
  margin-top: 0.5rem!important;
  border: 0!important;
  color: black!important;
  max-width: 6rem!important;
  margin-left: 10px!important;

}

@media only screen and (max-width: 766px) {
  .adm-list-item-content-arrow {
    padding-left: 0 !important;
  }

  .new-header-cart-modal {
    width: 94vw;
    margin-inline: 3vw;
    max-height: 90vh;
    height: max-content;
    overflow-y: scroll;
    position: absolute;
    top:5%;
    left: 0;
    z-index: 999;
    background-color: #FFFFFF;
    border-radius: 17px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px)
and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait) {
  .new-cart-order-confirm-message {
    font-size: 13px;
  }

  .new-cart-order-confirm-btn {
    font-size: 7px;
  }
}