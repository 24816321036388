header {
  top: 0;
  z-index: 2;
  height: 3rem;
  width: 100%;
  display: flex;
  color: #8dc491;
  position: sticky;
  min-height: 81px;
  align-items: center;
  background-color: #ffffff;
  justify-content: space-between;
  border-bottom: 1px solid #d0d0d0;
}

.pumaStatusbutton{
  margin: auto;
  border: 1px solid #0f9d58;
  background-color: #f7f7f7;
  color: #0f9d58;
  border-radius: 8px;
  margin-top: 10px;
  cursor: pointer;
  width: 5rem;
}

.pumaStatusbutton:focus{
  background: #FFF!important;
}

.Filter-Circle-Button {
  height: 2rem;
  margin-top: 0.3rem;
  display: flex;
  color: #6b6b6b;
  cursor: pointer;
  width: max-content;
  margin-inline: 6px;
  border-radius: 20px;
  align-items: center;
  padding-inline: 10px;
  background-color: #ededed;
  min-width: max-content !important;
}

.Filter-Circle-Button-Selected {
  height: 2rem;
  border: solid;
  margin-top: 0.3rem;
  display: flex;
  color: #F12535;
  cursor: pointer;
  margin-inline: 6px;
  width: max-content;
  border-radius: 20px;
  align-items: center;
  padding-inline: 10px;
  justify-content: center;
  background-color: #ededed;
  border-width: 2px !important;
  border-color: #F12535;
  min-width: max-content !important;
}

.Filter-Circle-Button-Container {
  display: flex;
  max-width: 95vw;
  overflow-x: auto;
  flex-direction: row;
  padding-bottom: 6px;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-snap-type: x mandatory;
  justify-content: space-between;
}

.Filter-Circle-Button-Container::-webkit-scrollbar {
  display: none
}

.Filter-Circle-Button-Container::-webkit-scrollbar-track {
  display: none
}

.Filter-Circle-Button-Container::-webkit-scrollbar-thumb {
  display: none
}


.input-field > input {
  border-bottom: 1px solid #c2dad8 !important;
}

.header__logo {
  height: 70%;
  cursor: pointer;
  margin-left: 1%;
}

.header__login {
  cursor: pointer;
  font-size: 3rem !important;
}

.Cashier-header-main-content {
  position: absolute;
  display: flex;
  height: auto;
}

.Cashier-header-content {
  display: flex;
  min-height: 64px;
  align-items: center;
}

.Cashier-header-content-logo {
  margin: 5px;
  height: 57px;
  cursor: pointer;
}

.Cashier-breadcrumb-home-content {
  top: 75px;
  height: 8%;
  z-index: 2;
  display: flex;
  color: #8dc491;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 3%;
}

.Status-Container {
  margin-inline: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Cashier-breadcrumb-home-content-editing {
  height: 8%;
  z-index: 1;
  width: 100vw !important;
  display: flex;
  color: #8dc491;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f7f7f7;
  padding-left: 3%;
  padding-right: 2%;
  margin-top: 10px;
}

.Breadcrumb-editing-container {
  margin-top: 25px;
  padding-inline: 1%;
  display: flex;
  flex-direction: row;
}

.Cashier-breadcrumb-left-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 1.5rem !important;
}

.ant-breadcrumb {
  width: max-content !important;
  height: auto !important;
  box-shadow: none !important;
  background-color: transparent !important;

}

.Cashier-breadcrumb-home-icon {
  color: #70b96c;
  cursor: pointer;
  margin-left: 0.5rem;
  font-size: 1rem !important;
}

.Cashier-breadcrumb-span {
  color: #6b6b6b;
  font-size: smaller;
}

.Cashier-header-search {
  margin: 0 !important;
  border: none !important;
}

.Cashier-header-cart {
  display: flex;
  color: #dadada;
  cursor: pointer;
}

.Cashier-header-cart__full {
  color: orange;
  font-size: 1rem;
  animation-duration: 0.5s;
  animation-name: cart__full;
}

.Cashier-header-cart__empty {
  color: #dadada;
  font-size: 1rem;
}

@keyframes cart__full {
  0% {
    font-size: 1rem;
  }
  25% {
    font-size: 1.3rem;
  }
  50% {
    font-size: 1.2rem;
  }
  100% {
    font-size: 1rem;
  }
}

.Alert-stock {
  z-index: 3;
  position: fixed;
}

.Cashier {
  float: left;
  width: 100%;
  height: 100%;
  display: block;
}

.Cashier-spinner {
  position: relative;
}

.Cashier-item-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5%;
  padding-top: 125px;
  padding-bottom: 2%;
  flex-direction: row;
}

.Cashier-item-list-editing {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1%;
  padding-top: 125px;
  padding-bottom: 2%;
  flex-direction: row;
}

.Cashier-item-list-empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Cashier-item {
  width: 45%;
  float: left;
  height: 100%;
  display: flex;
  margin-top: 10px;
  margin-left: 3.3%;
  font-size: 1.5rem;
  border-radius: 3px;
  align-items: center;
  flex-direction: column;
  border: 1px solid #dadada;
  background-color: rgba(255, 255, 255, 0.9);
}

.Cashier-category-item {
  height: 43%;
}

.Cashier-item .ant-carousel {
  width: 100%;
}

.Cashier-item-carousel-slide {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.Cashier-item__full {
  border: 1px solid #c6e8c4;
}

.Cashier-item-productImg {
  width: 100%;
  height: 80%;
  object-fit: contain;
}

.Cashier-cart-item-productImg {
  width: 90px;
  margin: 5px;
  height: 100px;
}

.Max-header-cart-modal-Title {

  font-size: 16px;
  font-weight: 790;
  line-height: 21px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  padding-left: 1rem;
  color: #0F1D3C;

}

.Cashier-category-item-productImg {
  height: 90%;
  aspect-ratio: 1 !important;
}

.Cashier-category-item-productIcon {
  width: 100%;
  height: 90%;
  color: silver;
  cursor: pointer;
}

.Cashier-item-img-quantity {
  width: 15%;
  color: #ffffff;
  margin-left: 85%;
  font-size: 1.1rem;
  text-align: center;
  position: absolute;
}

.Cashier-item-img__full {
  color: #fafafa;
  background-color: green;
}

.Cashier-item-name {
  height: 39px;
  margin: 10px;
  overflow: hidden;
  font-size: 0.8rem;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.cashier-item-description {
  margin: 7px;
  height: 75px;
  color: #b5b5b5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  flex-direction: column;
  justify-content: center;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.Cashier-item-description {
  width: 100%;
  float: left;
  color: #b5b5b5;
  font-size: 0.8rem;
  text-align: center;
  margin: 6px 3px 2px 0;
}

.Cashier-item-controls-content {
  display: flex;
  height: 91px;
  flex-direction: column;
}

.Filter-Text {
  font-size: 0.9rem;
  margin-top: 0.9rem;
  font-family: 'Sweet Sans Pro';
}

.Cashier-item-quantity {
  display: flex;
  font-size: 1rem;
  color: #0F1D3C;
  align-items: center;
  justify-content: center;
}

.Cashier-item-list .Cashier-item-quantity {
  min-width: 60px;
  font-size: 0.9rem;
}

.Cashier-item-price {
  margin: 0;
  text-align: center;
}

.Cashier-item-price-Promo {
  margin: 0;
  text-align: center;
  color: rgba(107, 104, 112, 0.51);
  font-size: 13px;
  text-decoration: line-through;
}

.Cashier-item-price-withOutPromo {
  margin: 0;
  padding-top: 4px;
  text-align: center;
}

.Cashier-item-withOutStock {
  border-radius: 20px;
}

.Cashier-item-variable-price {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Cashier-item-variable-price input {
  width: 5rem !important;
  height: 2rem !important;
}

.Cashier-item-variable-price i {
  font-size: 1.5rem !important;
}

.Cashier-item-variable-price input {
  margin-left: 5px !important;
}

.Cashier-category-img {
  width: 100%;
  display: flex;
  height: 120px;
  margin-top: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Cashier-category-name {
  width: 100%;
  height: 70px;
  display: flex;
  font-size: smaller;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Cashier-item-img {
  height: 12em;
  width: 100% !important;
  display: flex;
  color: silver;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Cashier-item-draw {
  width: 100%;
  position: relative;
}

.Cashier-item-draw-details {
  display: flex;
  margin-bottom: 2px;
  flex-direction: row;
  justify-content: center;
}

.Cashier-item-draw-open {
  color: #bebebe;
  font-size: 1.5rem;
}

.Cashier-item-draw .ant-drawer-content-wrapper {
  position: absolute;
  width: 100% !important;
  height: 135px !important;
}

.Cashier-item-draw-details-content {
  width: 100%;
  height: 73px;
  display: contents;
  flex-direction: column;
  justify-content: center;
  min-width: max-content;
}

.Cashier-props-detail {
  margin: 5px;
  font-size: 0.7rem;
}

.Cashier-item-draw-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.Cashier-item-draw-detail {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Cashier-item-cart-draw-details {
  width: 100%;
  display: flex;
  color: #bfbfbf;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: flex-start;
}

.PayOrder-paymentMethod {
  color: #979898;
  font-size: 1.5rem;
  margin-bottom: 0.5em;
}

.Form .Button-Pay-Card {
  clear: both;
  margin-right: 0;
  margin-top: 10px;
}

.PayOrder-amount {
  font-size: 2rem;
  color: #70b96c;
}

.change-price {
  color: #979898;
  text-decoration: line-through;
}

.PayOrder-price-detail {
  display: block;
}

.PayOrder-loyalty-points {
  display: flex;
  margin-top: -20px;
  margin-bottom: 10px;
  justify-content: center;
}

.ant-checkbox {
  margin-right: 0.3rem;
}

[type="checkbox"] + span:not(.lever):before, [type="checkbox"]:not(.filled-in) + span:not(.lever):after {
  border: none;
}

[type="checkbox"] + span:not(.lever) {
  padding-left: 24px;
}

[type="checkbox"]:checked + span:not(.lever):before {
  border-right: 2px solid white;
  border-bottom: 2px solid white;
}

.ready {
  display: flex;
  color: #0f9d58;
  align-items: center;
  justify-content: center;
}

.fail {
  color: #d27c94;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pending {
  color: #d2ba7c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Cashier-status-text {
  font-size: 1.6rem;
  width: 90%;
  margin-left: 5%;
}

.Cashier-status-text-Max{
  font-family: 'Sweet Sans Pro';
  color: #000;
  font-size: 1rem;
}

.statusMaxContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 1rem;
}

.dividerMaxFactura{
  background-color: #FAA21D;
  width: 100%;
  height: 1px;
}

.Cashier-status-text-MaxTitle {
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
  text-transform: uppercase;
  font-size: 20px;
  padding-inline: 1.2rem;
  font-weight: bolder;
}

.maxThTF{
 max-height: 2rem!important;
  padding: 0;
  border: 0;
  margin: 0;
}
.logoBelca{
width: 200px;
align-self: center;
  margin-block: 2rem;
}
.maxThText{
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;

}

.MaxDivider{
  width: 90vw;
  height: 1px;
  align-self: center;
  background-color: #D9D9D9;
  margin-block: 1.5rem;
}

.maxThTextV{
  font-family: 'Sweet Sans Pro';
  color: #565656;
  text-transform: lowercase;
  font-size: 0.7rem;
  padding: 0.2rem;
  margin: 0;
}
.maxThTextIva{
  font-family: 'Sweet Sans Pro';
  color: #565656;
  font-size: 0.7rem;
  padding: 0.2rem;
  margin: 0;
}
.Cashier-status-price-detail {
  display: block;
  font-size: 1.4rem;
}
.MaxLinks{
  font-family: 'Sweet Sans Pro';
  color: #EBA039!important;
  font-weight: bold;
  margin-block: 0.2rem!important;
}
.Button-confirm-div {
  width: 100%;
}

.Button-confirm-miscellaneous {
  margin-bottom: 15px;
  color: #FFFFFF !important;
}
.PDFDownCont{
  background-color: #FFF;
  height: 100vh;
  width: 100vw;
  padding-inline: 5vw;
}

.PDFloadingCont{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.PDFloadingText{
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
  font-size: 1rem;
  text-align: left;
  padding-inline: 5vw;
  line-height: 1.2rem;
}

.PDFloadingTitle{
  font-family: 'Sweet Sans Pro';
  color: white;
  font-size: 1.1rem;
  height: 1.8rem;
  display: flex;
  border-radius: 1.2rem;
  background-color: #0F1D3C;
  text-align: center;
  align-items: center;
  justify-content: center;
  line-height: 1.2rem;
  margin-top: 3rem;
}

.Button-confirm-miscellaneous-Max:disabled{
  margin-bottom: 15px;
  font-family: 'Sweet Sans Pro';
  color: #FFFFFF !important;
  background-color: #EBA039!important;
  border: 0;
  text-transform: uppercase;
  border-radius: 47px;
  width: 15rem;
  height: 2.5rem;
}
.Button-confirm-miscellaneous-Max {
  margin-bottom: 15px;
  font-family: 'Sweet Sans Pro';
  color: #FFFFFF !important;
  background-color: #EBA039!important;
  border: 0;
  text-transform: uppercase;
  border-radius: 47px;
  width: 15rem;
  height: 2.5rem;

}


.Cashier-status-resume {
  display: grid;
  margin-top: 10px;
  align-self: end;
  margin-left: 15px;
}

.Ticket-response-actions {
  display: flex;
  justify-content: center;
}

.Ticket-response-p {
  margin-top: 5px;
  margin-right: 20px;
}

.capsule-tabs-bottom {
  position: relative;
  top: 85px !important;
  background-color: #f5f5f5;
  margin-top: 30px;
  padding-inline: 3%;
  max-width: min-content;
  z-index: 1;
}

.capsule-tabs-bottom-editing {
  position: relative;
  background-color: #f5f5f5;
  margin-top: 20px;
  max-width: min-content;
}

.capsule-tabs-top {
  position: relative;
  background-color: #f7f7f7;
  margin-top: 0;
  max-width: 80vw;
}

.adm-capsule-tabs-tab {
  color: #6b6b6b;
  background-color: #ededed !important;
}

.adm-capsule-tabs-tab-active {
  height: 38px;
  border-width: 3px !important;
  color: #F5222D;
  background-color: #ededed !important;
  border: solid;
  border-width: 2px !important;
}

.Cashier-slider-dots {
  z-index: 1 !important;
  top: 265px !important;
}

.Cashier-search-button {
  visibility: hidden;
}

.ant-divider-horizontal {
  margin: 0 !important;
}

.Ticket-scroll-button {
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  position: fixed;
  visibility: visible;
  background-color: #70b96c;
}

.Cashier-item-combo {
  position: absolute;
  font-size: 1rem;
  text-align: center;
  bottom: 0;
  left: 25%;
  width: 50%;
  color: #7CB305;
}

@media screen and (max-width: 600px) {
  .Cashier-slider-dots {
    top: 155px !important;
  }
}

@media screen and (min-width: 601px) {
  .Cashier-item {
    width: 19%;
    margin-left: 0.8%;
  }

  .Cashier-category-item {
    width: 19%;
    height: 200px;
    margin-left: 0.8%;
  }

  .Cashier-item-list {
    padding-top: 40px;
    min-height: calc(89% - 146px);
  }

  .Cashier-item-list-editing {
    padding-top: 10px;
    min-height: calc(89% - 146px);
  }

  .Cashier-item-img {
    height: 21em;
  }
}

@media only screen and (max-width: 766px) {

  .Cashier-pay-btn {
    margin-bottom: 200px;
  }

  .capsule-tabs-bottom {
    margin-top: 60px !important;
  }

  .Cashier-item-list {
    padding-top: 65px;
    margin-top: 75px;
    min-height: calc(100vh - 285px);
  }

  .Cashier-item-list-editing {
    padding-block: 50px;
    min-height: calc(100vh - 285px);
  }

  .Cashier-search-button {
    z-index: 1;
    right: 20px;
    bottom: 20px;
    position: fixed;
    visibility: visible;
    background-color: #70b96c !important;
  }
}

input:not([type]), input[type=text]:not(.browser-default), input[type=password]:not(.browser-default), input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), input[type=search]:not(.browser-default), textarea.materialize-textarea {
  margin: 0;
  border: none;
}

input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  box-shadow: none;
  border-bottom: none;
}

.ant-carousel .slick-dots li button {
  background: #000;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #4982ff;
}

.ant-radio-button-wrapper {
  width: 100%;
  margin-bottom: 3px;
}

footer {
  bottom: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  height: 241px;
  padding: 15px;
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  margin-top: 46px;
  min-width: 1024px;
  text-align: center;
  align-items: center;
  line-height: 1.33337;
  letter-spacing: -.01em;
  background-color: #ffffff;
  border-top: 1px solid #e8e8e8;
}

.footer-content {
  margin: 0 auto;
  max-width: 980px;
  padding: 0 22px;
}

.footer-section {
  border-top: none;
}

.footer-shop {
  margin-bottom: 7px;
  padding-bottom: 8px;
  border-bottom: 1px solid #d2d2d7;
}

.footer-legal {
  position: relative;
  top: -3px;
  z-index: 1;
}

.footer-legal-copyright {
  margin-right: 30px;
  float: left;
  margin-top: 10px;
}

.footer-legal-links {
  margin-right: 30px;
  float: left;
  margin-top: 5px;
}

.footer-legal-link {
  border-right: 1px solid #d2d2d7;
  margin-right: 7px;
  padding-right: 10px;
  display: inline-block;
  margin-top: 5px;
  white-space: nowrap;
  color: #979898;
  text-decoration: none;
}

.footer-logos {
  float: right;
  margin-top: 5px;
  position: relative;
  top: -3px;
  z-index: 2;
  white-space: nowrap;
}

.card-img-footer {
  height: 40px;
}

.ant-input-group-addon {
  background-color: transparent;
}

html {
  scroll-behavior: smooth;
}

/* Layout */
main {
  max-width: 700px;
  margin: 3rem auto;
  padding: 0 1rem;
}

#scroll-to-bottom {
  font-weight: bold;
  color: green;
}

@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }

  .page {
    margin: 0;
    width: initial;
    border: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    border-radius: initial;
    page-break-after: always;
  }
}

@page {
  size: A4;
  margin: 0;
}

.StepsManager {
  width: 90%;
  margin-left: 5%;
  margin-bottom: 15px;
}

.StepsManager-Actions {
  margin: 15px;
  display: flex;
  justify-content: flex-end;
}

.Button-Pay-Card {
  clear: both;
  margin-right: 5%;
  margin-bottom: 15px;
  color: #FFFFFF !important;
}

.storePhone {
  color: #5491F5;
  transform: rotate(36deg);
  font-size: 1.3rem !important;
}

.storeLocation {
  color: #5491F5;
  font-size: 2rem !important;
}

.Cashier-modal-button {
  border: none;
  height: 2rem;
  color: white;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 20px;
  background-color: #70b96c;
}

.container-modal {
  overflow-scrolling: auto;
}

.modal-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ant-modal-title {
  margin: 0 0.5rem;
  font-weight: 600;
}

.Modal-Product-Description {
  font-weight: 600;
}

.modal-detail-container-img {
  display: flex;
  justify-content: center;
}

.modal-detail-container-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-detail-item-img {
  height: 12em;
  display: flex;
  color: silver;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.modal-detail-item-img i {
  font-size: 8rem;
}

.modal-detail-details {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal-detail-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 0.5rem;
  margin-bottom: 0.7rem;
}

.modal-detail-promoPrice {
  color: #9c9d9d;
  margin-bottom: 0.5rem;
  text-decoration-line: line-through;
}

.modal-detail-rate {
  margin-bottom: 0.5rem;
}

.ant-rate-star-first, .ant-rate-star-second {
  color: #d8d8d8;
}

.modal-favorite > span > svg {
  width: 25px;
  height: 25px;
  color: #d8d8d8;
  cursor: pointer;
}

.modal-favorite-selected span > svg {
  width: 25px;
  height: 25px;
  color: #fadb14;
  cursor: pointer;
}

.ant-list-split .ant-list-header {
  border-bottom: 1px solid #d8d8d8;
}

.modal-detail-container-rateAndPrice {
  display: flex;
  justify-content: space-between;
}

.modal-detail-container-combos {
  display: flex;
  padding-top: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.modal-detail-container-combos-text-container {
  display: flex;
  flex-direction: column;
}

.modal-detail-container-combos-title {
  background-color: white;
  font-size: 18px;
  font-weight: 600;
  margin: 0 !important;
}

.modal-detail-container-combos-subtitle {
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  color: #888888;
  margin: 0 !important;
}

.modal-detail-container-combos-down {
  color: #BFBFBF;
  font-size: 26px;
}

.modal-detail-combos-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 5px;
}

.modal-detail-combos-item-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.modal-detail-combos-item-title {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin: 0 !important;
}

.modal-detail-combos-circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #BFBFBF;

}

.Icon-Promotion-Text {
  display: block;
  font-size: 1.1rem;
  margin-left: 0.5rem;
  font-variant: tabular-nums;
}

.Cashier-header-cart-modal-product {
  position: relative;
}

.Cashier-slider-dots-off > li {
  display: none !important;
}

.Cart-Icon-Outlined-Gift {
  top: 70%;
  left: 90%;
  z-index: 1;
  color: #FF0000;
  text-align: center;
  position: absolute;
}

.Modal-Icon-Outlined-Gift {
  top: 10%;
  left: 5%;
  z-index: 1;
  color: #FF0000;
  text-align: center;
  position: absolute;
}

.Ticket-container-price {
  position: relative;
}

.Ticket-Icon-Outlined-Gift {
  z-index: 1;
  color: #FF0000;
  text-align: center;
  position: absolute;
}

.Ticket-status-price {
  margin-left: 6px;
}

.Sort-Icon {
  color: #595959;
  margin-right: 0rem;
  font-size: 25px;
  z-index: 9999;
}

.filterMainContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 95% !important;
  max-width: 60vw !important;
  z-index: 1;
}

.Cashier-promo {
  max-height: 300px;
  margin-top: 4rem;
}

.ant-carousel .slick-slider .slick-track, .ant-carousel .slick-slider .slick-list {
  width: 100%;
}

.Cashier-item-carousel {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 1rem;
}

.Cashier-item-carousel-promotions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Cashier-carousel-promotion {
  display: flex;
  height: 343px !important;
  margin: 2rem;
  width: 334px;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  border: 1px solid #D9D9D9;
  box-shadow: 0 4px 4px 0 #00000040;
}

.Cashier-carousel-promotion-text {
  color: #F5222D;
  font-weight: 900;
  font-size: 2.8rem;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  font-family: Roboto, sans-serif;
}

.Icon-Outlined-Gift-carousel {
  display: flex;
  color: #F5222D;
  margin-bottom: 1.2rem;
  justify-content: center;
}

.Icon-Outlined-Gift-carousel > span {
  font-size: 3.2rem !important;
}

.Icon-Outlined-Gift-card {
  width: 20%;
  color: #FF0000;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 2% !important;
  left: 2% !important;
}

.Cashier-slider-dots {
  display: flex;
  margin-top: 0.6rem;
}

.ant-carousel .slick-dots-bottom {
  bottom: 0;
  position: static;
}

.Cashier-promotion {
  display: grid;
  grid-gap: 0.8rem;
  margin: 0 0.9rem;
  margin-top: 11rem !important;
  grid-template-columns: repeat(2, 1fr);
  overflow-x: scroll;
}

.Cashier-promoton-items-container {
  width: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.Cashier-promoton-items-container::-webkit-scrollbar {
  display: none;
}


.Cashier-promotion-editing {
  display: flex;
  width: 100%;
  height: 180px;
  overflow: hidden;
  align-items: center;
}

.Cashier-card-promo {
  width: 173px;
  display: flex;
  height: 190px;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  border: 1px solid #D9D9D9;
  margin-top: 4rem;
}

.Cashier-card-promo > div {
  padding: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.Icon-Outlined-Gift-promo {
  display: flex;
  color: #F5222D;
  margin-bottom: 1.2rem;
  justify-content: center;
}

.Cashier-promotion-text {
  color: #F5222D;
  font-weight: 900;
  font-size: 1.7rem;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  font-family: Roboto, sans-serif;
}

.Cashier-container-card-promo {
  width: 142px;
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.Cashier-container-item {
  margin-top: 7rem;
}

.Cashier-promotion-empty {
  min-height: 300px;
}

.breadcrumb-promotion {
  color: #6b6b6b;
  font-size: smaller;
}

.adm-scroll-mask {
  background: transparent !important;
}


.adm-capsule-tabs-header {
  max-width: 92vw !important;
  padding: 5px !important;
  border-width: 0 !important;
  background-color: #f7f7f7 !important;
}

.Cashier-container-promoAndFilter {
  justify-content: flex-end;
  display: flex;
}
.tdstatus {
  width: 82px !important;
  max-width: 82px !important;
  min-width: 82px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Sweet Sans Pro";
  white-space: nowrap;
}
@media only screen and (max-width: 600px) {
  .Filter-Circle-Button-Container {
    width: 86% !important;
  }

  .Cashier-breadcrumb-left-container {
    width: 95% !important;
  }

  .Icon-Outlined-Gift {
    top: 1%;
  }

  .Icon-Outlined-Gift .anticon {
    font-size: 30px !important;
  }

  .Icon-Promotion-Text {
    font-size: 1rem;
    margin-top: -4px;
    margin-left: 0.2rem;
    font-family: Roboto, sans-serif;
  }

  .Ticket-status-price {
    margin-left: 24px;
  }

  .thstatus {
    width: 82px !important;
    max-width: 82px !important;
    min-width: 82px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tdstatus {
    width: 82px !important;
    max-width: 82px !important;
    min-width: 82px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Sweet Sans Pro";
    white-space: nowrap;
  }

  .transaction-status-table {
    overflow-x: scroll;
    flex: 1;
    display: flex;
    flex-direction: column;

  }

  .Status-Container {
    max-width: 100vw !important;
    margin-inline: 5%;

  }
}

@media only screen and (min-width: 374px) {
  .Cashier-container-card-promo {
    width: 170px;
  }
}

@media only screen and (min-width: 425px) {
  .adm-capsule-tabs-header {
    width: 300px;
  }

  .Cashier-container-card-promo {
    width: 200px;
  }
}

@media only screen and (min-width: 480px) {
  .Cashier-promotion {
    grid-template-columns: repeat(3, 1fr);
  }

  .Cashier-promotion-text {
    font-size: 1.6rem;
  }

  .Cashier-promo {
    max-height: 330px;
  }

  .adm-capsule-tabs-header {
    width: 400px;
  }
}

@media only screen and (min-height: 741px) {
  .ant-image {
    width: 320px !important;
  }
}

@media only screen and (min-width: 769px) {
  .modal-detail-container-img > div > img {
    height: 80%;
    object-fit: cover;
  }

  .ant-image {
    width: 350px !important;
  }

  .Cashier-carousel-promotion-text {
    font-size: 3rem;
  }

  .Cashier-promotion-empty {
    min-height: 535px;
  }

  .adm-capsule-tabs-header {
    width: 100%;
  }

  .ant-breadcrumb {
    width: 100%;
  }

  .Cashier-slider-dots-promo {
    margin-block: 0.5rem !important;
  }
}

@media only screen and (min-width: 1025px) {
  .modal-detail-container-img > div > img {
    height: 80%;
    object-fit: cover;
  }

  .ant-image {
    width: 360px !important;
  }

  .modal-favorite > span > svg:hover {
    color: #fadb14;
  }
}

.status-footer-container {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
}

.CashierRedirectButton {
  margin-block: 2rem;
  align-self: flex-end;
  border: 1px solid #0f9d58;
  color: #0f9d58;
  background-color: #FFFFFF;
  cursor: pointer;
  border-radius: 8px;
  height: 1.8rem;
}