.Form-shipmentCO {
  margin: 0 1rem;
  padding: 0 1rem;
}

.Form-input-error {
  margin-top: -33px;
}

@media screen and (min-width: 768px){
  .Form-shipmentCO {
    margin: 0 5rem;
  }
}