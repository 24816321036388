.skeletonToroso{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.skeletonPrice{
  display: flex;
  justify-content: center;
  margin: 16px 0;
}
.skeletonPrice > span{
  margin-top: 8px !important;
  height: 44px !important;
  width: 225px !important;
}
.skeletonInput{
  display: flex;
  justify-content: center;
}
.skeletonInput > span{
  width: 90% !important;
  height: 550px !important;
}
@media only screen and (max-width: 766px) {
  .skeletonInput > span {
    height: 600px !important;
  }
}