

.PromotionScreen-Container{
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.PromotionScreen-button-next{
  background: #F4F0F3;
  border: 1px solid #46354D;
  border-radius: 47px;
  height: 39px;
  width: 122px;
  color: #46354D;
  border-radius: 47px;
  align-self: flex-end;
  position: fixed;
  bottom: -2vh;
  right: 3vh;
  z-index: 997;
}

.PromotionScreen-header{
  display: flex;
  flex-direction: row;
  min-width: 100% !important;
  justify-content: space-between;
  align-items: center;
  padding-inline: 3%;
  margin-top: 3%;
}

.PromotionScreen-Title{
  color: #000;
  font-family: 'Sweet Sans Pro';
  font-size: 25px;
}

.PromotionScreen-seeAll{
  color: #46354D;
  font-size: 13px;
  font-family: 'Sweet Sans Pro';
  cursor: pointer;
}

.New-Icon-Outlined-Gift-card {
  width: 20%;
  color: #F12535;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 2% !important;
  left: 8% !important;
}

.New-Icon-Promotion-Text{
  color: #F12535;
  display: block;
  font-size: 1rem;
  margin-top: -0.4rem;
  margin-left: 0.5rem;
  font-family: 'Sweet Sans Pro';
}


.PromotionScreen-carousel{
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding-bottom: 20px;
  margin-bottom: 30px;
  padding-inline: 2vw;
  width: auto;
}
.PromotionScreen-carousel::-webkit-scrollbar {
  display: none;
}

.PromotionScreen-paginator-cont{

  margin-top: -1.5rem;
  margin-bottom: 2rem;
  width: 12vw;
  min-width: 7rem;
  margin-inline:auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  color: #2c2c2c;
}

.PromotionScreen-icon{
  font-size: 1.2rem;
  color: #137CC1;
}
.PromotionScreen-icon-d{
  font-size: 1.2rem;
  color: #838383;
}

.PromotionScreen-Card-BS{
  min-width: 15rem;
  width: 15rem;
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  padding-bottom: 1rem;
  height: 23rem!important;
  margin-bottom: 3%;
  justify-content: center;
  max-width: 270px;
  margin-inline: 0.3rem;
}

.loader-fb-promotion {
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  position: relative !important;
}
.loader-fb-promotion, .loader-fb-promotion:before, .loader-fb-promotion:after {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 50px;
  background-color: #137CC1;
  border: 1px solid #0f6da6;
  z-index: 100000;
  content: ' ';
  margin-left: -5px;
  margin-right: -7px;
}
.loader-fb-promotion:before {
  top: -11px;
  left: -100%;
  animation: loading-fb .8s cubic-bezier(.4,.5,.6,1) infinite;
}
.loader-fb-promotion {
  animation: loading-fb-main .8s cubic-bezier(.4,.5,.6,1) .2s infinite;
}
.loader-fb-promotion:after {
  top: -11px;
  right: -100%;
  margin-top: 50%;
  animation: loader-fb-promotion .8s cubic-bezier(.4,.5,.6,1) .4s infinite;
}

@keyframes loader-fb-promotion {
  from {
    transform: scaleY(1.4);
    background-color: rgba(5, 8, 9, 0.55);
    border: 1px solid rgba(5, 8, 9, 0.55);
  }
}

@keyframes loader-fb-promotion-main {
  from {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: -10px;
    background-color: rgba(5, 8, 9, 0.55);
    border: 1px solid rgba(5, 8, 9, 0.55);
  }
}

.PromotionSpinner{
  height: 26rem!important;
  width: 100vw;
  display: flex;
  z-index: 1;
}

.PromotionScreen-TagsFilled{
  font-size: 20px;
  color: #F12535;
}

.PromotionScreen-Card{
  min-width: 15rem;
  max-width: 15rem;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  padding: 0;
  padding-bottom: 0.5rem;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.PromotionScreen-purchaseCont{
  padding-inline: 0.5rem;
}
.PromotionScreen-productImg{
  background-size: contain!important;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  background-color: #FFFFFF;
  width: 90%;
  align-self: center;
  height: 192px;
  margin-top: 8px;

}


.PromotionScreen-Card-Flag{
  position: relative;
  top: 15px;
  left: 0px;
  width: 25%;
  height: 25px;

  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #F12535;
  font-family: 'Sweet Sans Pro';
  border-radius: 8px;
}

.PromotionScreen-name{
  font-family: 'Sweet Sans Pro';
  font-size: 15px;
  max-height: 45px;
  overflow: hidden;
  margin: 0;
}
.PromotionScreen-textContainer{
  display: flex;
  flex-direction: column;
  padding-inline: 5%;
  margin: 0;
  justify-content: space-between;
  height: 8rem;

}

.PromotionScreen-priceContainer{
  height: max-content;
  display: flex;

  flex-direction: column;
  justify-content: space-between;
}



.PromotionScreen-price{
  font-family: 'Sweet Sans Pro';
  font-size: 20px;
  color: grey;
  margin: 0;
  text-decoration: line-through;
}

.PromotionScreen-promoPrice{
  font-family: 'Sweet Sans Pro';
  font-size: 23px;
  color: #46354D;
  margin: 0;
  margin-bottom: 5px;
}

.PromotionScreen-BS-carousel{
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow-x: scroll;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.PromotionScreen-banner-carousel{
  width: 100%;
  height: 30vh;
  background-color: #6b6b6b;
  display: flex;
  flex-direction: row;
}
.PromotionScreen-banner{
  min-width: 30vw;
  height: 58vh;
  background-size: cover;
  background-repeat:no-repeat;
  background-position: center center;
  overflow-x: hidden;
  margin-inline: 2%;
}

.PromotionScreen-welcome{
  background: url("https://cloudfront-us-east-1.images.arcpublishing.com/infobae/GGNZ6XTIUBGJ5J7LYDUUQIEAGA.jpg");
  background-size: cover;
  height: 40vh;
  width: 65%;
  align-self: center;
  background-position: center 60%;
  display: flex;
  text-align: left;
  padding: 2vw;
  margin-inline: 1%;
}

.PromotionScreen-welcome-text{
  font-family: 'Sweet Sans Pro';
  color: #0c0c0c;
  font-size: 30px;
  text-align: left;
}

.PromotionScreen-left{
  position: absolute;
  font-size: 1.2rem;
  font-family: 'Sweet Sans Pro';
  top: 6rem;
  left: 0.5rem;
  color: #137CC1;
}

.PromotionScreen-AllPromo-Container{
  display: flex;
  flex-direction: column;
}

.PromotionScreen-AllPromo-Grid{
  display: flex;
  flex-wrap: wrap;
  margin-top: 1%;
  padding-bottom: 2%;
  flex-direction: row;
  justify-content: space-evenly;
}
.PromotionScreen-BS-Grid{
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  overflow-x: scroll;
  padding-bottom: 2%;
  flex-direction: row;
}
.PromotionScreen-button-next {
  text-align: center;
  z-index: 997;
  align-self: end;
  font-size: 1.1rem;
  alignItems: center;
  font-family: 'Sweet Sans Pro';
  background-color: #FFF;
  padding-bottom: 2px;
  width: 8rem;
  color: #137CC1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-color: #137CC1;
  border-radius: 47px;
  border-width: 2px;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 1025px) {
  .PromotionScreen-Title{
  
    color: #000;
    font-size: 20px;
  }

  .PromotionScreen-price{
    font-size: 15px;
  }

  .PromotionScreen-promoPrice{
    font-size: 18px;
  }

  .PromotionScreen-name{
    font-size: 13px;
  }


  .PromotionScreen-welcome{
    background: url("https://cloudfront-us-east-1.images.arcpublishing.com/infobae/GGNZ6XTIUBGJ5J7LYDUUQIEAGA.jpg");
    background-size: cover;
    height: 45vh;
    padding-inline: 2vw;
    width: 100%;
    background-position: center 82%;
    display: flex;
    text-align: left;
    margin-bottom: 5%;
  }

  .PromotionScreen-welcome-text{
    font-family: 'Sweet Sans Pro';
    color: #0c0c0c;
    font-size: 23px;
    text-align: left;
    width: 70%;
  }

  .PromotionScreen-banner-carousel{
    width: 100%;
    height: 30vh;
    background-color: #6b6b6b;
    display: flex;
    flex-direction: row;
  }

  .PromotionScreen-banner{
    min-width: 100vw;
    height: 35vh;
    background-size: contain;
    background-repeat:no-repeat;
    background-position: center center;
    overflow-x: hidden;
  }



}
