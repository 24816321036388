@import url('https://fonts.cdnfonts.com/css/sweet-sans-pro');
.PurchaseProduct {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-clip: content-box;
  background-color: #70b96c;
  color: white;
  border-radius: 38px;
  padding: 5px 10px;
  width: 7rem;
}
.Max-product-Purchase{
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: center;
}
.PurchaseProduct i {
  font-size: 2rem !important;
}

.Max-item-withOutStock {
  border-radius: 20px!important;
  background-color: grey;
  width: 12rem;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Sweet Sans Pro';
  padding-bottom: 2px;
}

.Max-item-withStock {
  border-radius: 20px!important;
  background-color: #EBA039;
  width: 10rem;
  text-align: center;
  color: #fff;
  display: flex;
  font-size: 90%;
  justify-content: center;
  align-items: center;
  font-family: 'Sweet Sans Pro';
  padding-bottom: 2px;
}

.Max-item-withStockBS {
  border-radius: 20px!important;
  background-color: #EBA039;
  width: 8rem;
  margin-top: 1rem;
  text-align: center;
  color: #fff;
  display: flex;
  font-size: 0.8rem;
  justify-content: center;
  align-items: center;
  font-family: 'Sweet Sans Pro';
  padding-bottom: 2px;
}

Max-item-withOutStock-modal{
  border-radius: 20px!important;
  background-color: grey;
  width: 18rem;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Sweet Sans Pro';
  padding-bottom: 2px;
  height: 2rem;
}

.Max-item-withStock-modal {
  border-radius: 20px!important;
  background-color: #EBA039;
  width: 18rem;
  text-align: center;
  color: #fff;
  display: flex;
  font-size: 90%;
  justify-content: center;
  align-items: center;
  font-family: 'Sweet Sans Pro';
  padding-bottom: 2px;
  height: 2rem;
}
.PurchaseProduct__unavailable {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-clip: content-box;
  background-color: silver;
  color: white;
  border-radius: 20px;
  padding: 5px 10px;
  margin-left: 10%;
  margin-right: 10%;
}
