.NewSearchIcon{
  color: #117CC1;
  font-size: 1.2rem;
}

.NewSearchIconMax{
  color: #0F1D3C;
  font-size: 1.2rem;
}

.NewSearchContainer{
  border:1px solid #117CC1;
  width: 100%;
  background-color: #FFF;
  margin-inline:  1rem;
  height: 2.5rem;
  padding-inline: 1rem;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  flex-direction: row;
}
.NewSearchContainerMax{
  border:1px solid #0F1D3C;
  width: 100%;
  background-color: #FFF;
  margin-inline:  1rem;
  height: 2.2rem;
  padding-inline: 1rem;
  display: flex;
  align-items: center;
  border-radius: 15.5px;
  flex-direction: row;
}
.NewSearchInput{
  font-family: 'Sweet Sans Pro';
  font-size: 0.8rem;
  color: #000;
}
.NewSearchContainerDesktop {
  border:1px solid #117CC1;
  width: 18rem;
  background-color: #FFF;

  height: 2.5rem;
  padding-inline: 1rem;
  display: flex;
  align-items: center;
  align-self: flex-end;
  border-radius: 1rem;
}

.NewSearchContainerDesktopMax{
  border:1px solid #0F1D3C;
  width: 18rem;
  background-color: #FFF;

  height: 2.5rem;
  padding-inline: 1rem;
  display: flex;
  align-items: center;
  align-self: center;
  margin-right: 1rem;
  border-radius: 1rem;
}