.Editing-item-adding {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  border-radius: 1rem !important;
}

.Editing-item-adding .Cashier-item-adding-btn {
  color: #137CC1;
  border: none !important;
  background-color: #ffffff;
}

.Editing-item-adding-btn{

  width: 2.3rem!important;
  height:2.3rem!important;
  border-radius: 50% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white!important;

}
.Cashier-item-remove-btn{
  margin-top: 0.6rem;
  size: 0.8rem!important;
}
.Editing-item-adding .btn-floating {
  box-shadow: none;
  background-color: #ffffff;
  height: 3rem;
}

.Editing-item-adding .btn-floating[disabled] {
  border: none !important;
  background-color: #ffffff !important;
}

.Editing-item-adding .Cashier-item-adding-btn:hover, .Cashier-item-adding-btn:focus {
  border: none !important;
  background-color: #ffffff !important;
}

.Editing-item-adding .btn-floating i {
  color: #137CC1;
  font-size: 2rem !important;
}

.Editing-item-adding .btn-floating[disabled] i {
  color: #9F9F9F !important;
}

.Editing-item-adding .btn-floating[disabled] i  {
  color: #ffffff;
}
